<template>
  <div id="app">
    <div class="mask" v-if="!isFoundVehicleDetails"></div>
    <router-view/>
  </div>
</template>

<script>
export default {
  computed: {
    isFoundVehicleDetails() {
      const body = document.body;
      if(!this.$store.state.isFoundVehicleDetails) {
        body.classList.add('no-scroll');
      } else {
        body.classList.remove('no-scroll');
      }
      return this.$store.state.isFoundVehicleDetails;
    }
  }
}
</script>

<style lang="scss">
.no-scroll {
  overflow: hidden;
  height: 100vh;
}
@media (max-width:767px) {
  .no-scroll {
    overflow: scroll
  }
}
#app, .mask {
  min-height: 100vh;
}
.mask {
  background: #000;
  opacity: 0.6;
  position: absolute;
  z-index: 100;
  width: 100%;
}

</style>
